import React, {Component} from 'react';
import Parse from 'parse';
import config from '../config';
import _ from 'underscore';
import { log } from '../api/Log';

const DEFAULTS = {
	isLoggedIn: false,
	userId: null
}

const AppState = class AppState {
	constructor() {
		if (this.getCachedAppState()) {
			try {
				this.state = this.getCachedAppState();
			} catch (e) {
				log(e)
			}
		}
	}

	set(key, value) {
		log('setState ' + key, value)
		this.state[key] = value;
		window.localStorage.setItem('AppState', JSON.stringify(this.state));
	}

	get(key) {
		log('getState ' + key, this.state[key])
		return this.state[key];
	}

	getCachedAppState() {
		Parse.initialize(config.APP_ID);
		Parse.serverURL = config.PARSE_SERVER_URL;

		try {
			if(Parse.User.current() && window.localStorage.getItem('AppState')){
				log('setState', "cached state")
				return JSON.parse(window.localStorage.getItem('AppState'));
			} else {
				log('setState', "default state")
				this.state = DEFAULTS;
				return false;
			}
		} catch (e) {
			log(e)
			return false;
		}
	}

}

var appState = new AppState();

export default appState;
