import axios from 'axios';
import Parse from 'parse';
import config from '../config';
import BaseModel from './BaseModel';
import { log } from './Log';
import Payment from './Payment';
import User from './User';

var ACCOUNTING_URL = config.ICOUNT_URL;

const accountingTypes = {
	ICOUNT: 0,
	GROW_INVOICE: 1
};

const accountingTypesRev = {
	0: 'ICOUNT',
	1: 'GROW_INVOICE'
};

const accountingAction = {
	0: 'LOGIN',
	1: 'SYNC',
	2: 'RECEIPT',
	3: 'INVOICE'
};

var Accounting = class Accounting extends BaseModel {
	constructor() {
		super();
		this.accountingTypes = accountingTypes;
		this.accountingTypesRev = accountingTypesRev;
		this.accountingAction = accountingAction;
	}

	checkAccountingSessionValid(params, callback) {
		log("checkAccountingSessionValid", params);
		ACCOUNTING_URL = config.ICOUNT_URL + "company/info";
		this.postToAccounting(params, callback)
	}

	loginToAccounting(params, callback) {
		log("loginToAccounting");
		ACCOUNTING_URL = config.ICOUNT_URL + "auth/login";
		params["lang"] = User.getPreferences().language || "he";
		this.postToAccounting(params, callback)
	}

	getMedidateInvoices(callback) {
		Parse.Cloud.run('getUserMedidatePaidInvoices', {}, {
			success: function (result) {
				log('getMedidateInvoices - ', result);
				callback(null, result)
			},
			error: function (error) {
				log('getMedidateInvoices error', error);
				callback(error, null)
			}
		});
	}

	getDocUrl(invoice, callback) {
		Parse.Cloud.run('getDocUrl', { doctype: invoice.doctype, docnum: invoice.docnum }, {
			success: function (result) {
				log('getMedidateInvoices - ', result);
				callback(null, result)
			},
			error: function (error) {
				log('getMedidateInvoices error', error);
				callback(error, null)
			}
		});
	}

	syncStudentsToAccounting(syncParams, callback) {
		log("syncStudentsToAccounting");
		ACCOUNTING_URL = config.ICOUNT_URL + "client/create_or_update";
		User.getUserFollowers(Parse.User.current(), (err, students) => {
			if (err) {
				log(err);
				callback(err, null)
				return;
			} else {
				log(students);
			}

			User.getStudentsNotes({
				students: students
			}, (err, notes) => {
				if (err) {
					log(err)
					// callback(err, null)
					// return;
				}

				try {
					var studentNotesMapped = {};
					if (notes && notes.length) {
						for (var j = 0; j < notes.length; j++) {
							studentNotesMapped[notes[j].get('student').id] = notes[j];
						}
					}

					var usersArray = [];

					for (var i = 0; i < students.length; i++) {
						var newClient = students[i];
						var note = studentNotesMapped[newClient.id];
						var params = {
							email: note ? note.get('email') : newClient.get('username'),
							client_name: note ? ((note.get('first_name') || '') + ' ' + (note.get('last_name') || '')) : (newClient.get('first_name') || '') + ' ' + (newClient.get('last_name') || ''),
							first_name: note ? note.get('first_name') : (newClient.get('first_name') || ""),
							last_name: note ? note.get('last_name') : (newClient.get('last_name') || ""),
							phone: note ? note.get('phone_number') : (newClient.get('phone_number') || ""),
							home_country: note ? note.get('country') : (newClient.get('country') || ""),
							notes: note ? note.get("note") : ""
						}
						usersArray.push(params);
					}

					usersArray['lang'] = User.getPreferences().language || "he";
					// paramsArray['debug'] = true;

					log("usersArray", usersArray);
					this.postMultipleToAccounting(syncParams, usersArray, callback)
				} catch (e) {
					log(e)
					callback(e, null)
				}
			})
		})
	}

	generateReceiptOrInvoice(params, callback) {
		log("generateReceiptOrInvoice", params);
		ACCOUNTING_URL = config.ICOUNT_URL + "doc/create";
		this.generateReceiptOrInvoicePreview(params, (err, receiptOrInvoiceParams) => {
			if (err) {
				log(err);
				callback(err, null)
				return;
			} else {
				if (Parse.User.current().get('grow_seller_id')) {
					//Create GROW Document
					log("generateReceiptOrInvoice", receiptOrInvoiceParams);
					Parse.Cloud.run('sendGrowReceipt', receiptOrInvoiceParams, {
						success: function (result) {
							log('sendGrowReceipt - ', result);
							callback(null, result)
						},
						error: function (error) {
							log('sendGrowReceipt error', error);
							callback(error, null)
						}
					});
				} else {
					this.postToAccounting(receiptOrInvoiceParams, callback)
				}

			}
		})
	}

	generateReceiptOrInvoicePreview(props, callback) {
		log("generateReceiptOrInvoicePreview", props);
		var student = props.payment.get("buyer") || props.student;
		var membershipPaymentType = props.membershipPaymentType;
		log("student", student);
		log("student email", student.get('username'));
		log("student first name", student.get('first_name'));
		var payment = props.payment;
		log("payment", payment);
		var isInvoice = props.isInvoice;
		var multiplyer = 1;

		if (payment.get("membership") && membershipPaymentType === 0) {
			var isInMonths = payment.get("membership").get("expiration_type") == 1;
			multiplyer = (payment.get("membership").get("expiration_period") / (isInMonths ? 1 : 4));
		}

		User.getStudentNote({
			student: student
		}, (err, note) => {
			if (err) {
				log(err)
			}

			var PaymentObject = Parse.Object.extend(config.ENV === 'development' ? 'PaymentDebug' : 'Payment');
			var query = new Parse.Query(PaymentObject);
			query.equalTo('objectId', payment.id);
			query.include("checks_array")
			query.find({
				success: (updatedPayments) => {
					try {
						var updatedPayment = updatedPayments[0];
						log("updatedPayment", updatedPayment);
						log("student", student);
						log("note", note);
						updatedPayment["bank"] = payment.bank
						updatedPayment["branch"] = payment.branch
						updatedPayment["account"] = payment.account

						var items = [];
						var singleItem = {
							description: Payment.getPaymentType(payment) === Payment.paymentTypes.TRIAL ? this.getTitleFromPayment(updatedPayment) + " (" + showTextByBusinessType('paymentTypes.' + Payment.paymentTypesRev[Payment.getPaymentType(payment)]) + ")" : this.getTitleFromPayment(updatedPayment),
							unitprice_incvat: multiplyer * (updatedPayment.get("partial_amount") || updatedPayment.get("price")),
							quantity: 1,
						}

						items.push(singleItem)

						var params = {
							sid: props.sid,
							email: (note && note.get('email')) ? note.get('email') : student.get('username'),
							client_name: (note && note.get('first_name')) ? ((note.get('first_name') || '') + ' ' + (note.get('last_name') || '')) : (student.get('first_name') || '') + ' ' + (student.get('last_name') || ''),
							first_name: (note && note.get('first_name')) ? note.get('first_name') : (student.get('first_name') || ""),
							last_name: (note && note.get('last_name')) ? note.get('last_name') : (student.get('last_name') || ""),
							phone: (note && note.get('phone_number')) ? note.get('phone_number') : (student.get('phone_number') || ""),
							home_country: (note && note.get('country')) ? note.get('country') : (student.get('country') || ""),
							doctype: isInvoice ? "invrec" : "receipt",
							currency_code: updatedPayment.get("currency") || "ILS",
							items: items,
							doc_title: this.getTitleFromPayment(updatedPayment),
							hwc: updatedPayment.get("payment_method_extra_details") || "",
							sanity_string: config.ENV === 'development' ? updatedPayment.id + Math.floor(Date.now() / 1000) : updatedPayment.id,
							lang: User.getPreferences().language || "he",
							doc_lang: User.getPreferences().language || "he",
							send_email: props.sendEmail,
							email_to_client: props.sendEmail,
							email_cc_me: true
						}

						var paymentMethod = Payment.getPaymentType(updatedPayment);

						switch (paymentMethod) {
							case Payment.paymentTypes.CASH:
								params["cash"] = this.getAccountingPaymentItemFromPayment(updatedPayment, membershipPaymentType);
								break;
							case Payment.paymentTypes.CHECK:
								params["cheques"] = this.getAccountingPaymentItemFromPayment(updatedPayment, membershipPaymentType);
								break;
							case Payment.paymentTypes.BANK_TRANSFER:
								params["banktransfer"] = this.getAccountingPaymentItemFromPayment(updatedPayment, membershipPaymentType);
								break;
							case Payment.paymentTypes.OTHER:
								params["barter"] = this.getAccountingPaymentItemFromPayment(updatedPayment, membershipPaymentType);
								break;
							case Payment.paymentTypes.TRIAL:
								if (updatedPayment.get("payment_method_extra_type") === Payment.paymentTypes.CASH) {
									params["cash"] = this.getAccountingPaymentItemFromPayment(updatedPayment);
								} else if (updatedPayment.get("payment_method_extra_type") === Payment.paymentTypes.CHECK) {
									params["cheques"] = this.getAccountingPaymentItemFromPayment(updatedPayment);
								} else if (updatedPayment.get("payment_method_extra_type") === Payment.paymentTypes.BANK_TRANSFER) {
									params["banktransfer"] = this.getAccountingPaymentItemFromPayment(updatedPayment);
								} else if (updatedPayment.get("payment_method_extra_type") === Payment.paymentTypes.OTHER) {
									params["barter"] = this.getAccountingPaymentItemFromPayment(updatedPayment);
								}
								break;
							default:
								params["cash"] = this.getAccountingPaymentItemFromPayment(updatedPayment);
								break;
						}

						log("params", params);
						callback(null, params)
					} catch (e) {
						log(e)
						callback(e, null)
					}
				},
				error: (err) => {
					log(err);
					callback(e, null)
				}
			})
		})
	}

	getFromAccounting(params, callback) {
		log("getFromAccounting", JSON.stringify(params));
		log("getFromAccounting", ACCOUNTING_URL);

		try {
			let headers = {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json'
			}
			let axiosConfig = {
				'headers': headers
			};

			params['debug'] = true;
			axios.get(ACCOUNTING_URL, JSON.stringify(params), axiosConfig)
				.then(response => {
					log("response", JSON.stringify(response))
					callback(null, response)
				})
				.catch(error => {
					log("error", error);
					callback(error, null)
				});
		} catch (e) {
			log(e);
		}
	}

	postToAccounting(params, callback) {
		log("postToAccounting", JSON.stringify(params));
		log("postToAccounting", ACCOUNTING_URL);

		try {
			params['sendTo'] = ACCOUNTING_URL;

			Parse.Cloud.run('postToAccounting', params, {
				success: function (result) {
					log('postToAccounting - ', result);
					callback(null, result)
				},
				error: function (error) {
					log('postToAccounting error', error);
					callback(error, null)
				}
			});
		} catch (e) {
			log(e);
		}
	}

	postMultipleToAccounting(params, usersArray, callback) {
		log("postMultipleToAccounting", JSON.stringify(usersArray));
		log("ACCOUNTING_URL", ACCOUNTING_URL);

		try {
			log('postMultipleToAccounting sid', params.sid)
			// paramsArray['sendTo'] = ACCOUNTING_URL;
			var params = { 'sid': params.sid, 'sendTo': ACCOUNTING_URL, 'usersArray': usersArray };
			log("postMultipleToAccounting 2", JSON.stringify(usersArray));
			// Parse.Cloud.run('postMultipleToAccounting', usersArray, {
			Parse.Cloud.run('postMultipleToAccounting', params, {
				success: function (result) {
					log('postMultipleToAccounting - ', result);
					var dymmyData = {
						message: 'success syncing students',
						status: true
					}
					callback(null, dymmyData)
				},
				error: function (error) {
					log('postMultipleToAccounting error', error);
					callback(error, null)
				}
			});
		} catch (e) {
			log(e);
		}
	}

	getTitleFromPayment(payment) {
		return payment.get("session") ? payment.get("session").get("title") :
			payment.get("ticket") ? payment.get("ticket").get("title") :
				payment.get("membership") ? payment.get("membership").get("title") :
					payment.get("product") ? payment.get("product").get("title") :
						payment.get("teacher") ? payment.get("teacher").get("first_name") + " " + payment.get("teacher").get("last_name") :
							""
	}

	getAccountingPaymentItemFromPayment(payment, membershipPaymentType) {
		log("getAccountingPaymentItemFromPayment", payment);
		log("getAccountingPaymentItemFromPayment membershipPaymentType", membershipPaymentType);
		var paymentTypeObject;
		var paymentMethod = Payment.getPaymentType(payment);
		var multiplyer = 1;

		if (payment.get("membership") && membershipPaymentType === 0) {
			var isInMonths = payment.get("membership").get("expiration_type") == 1;
			multiplyer = (payment.get("membership").get("expiration_period") / (isInMonths ? 1 : 4));
		}

		switch (paymentMethod) {
			case Payment.paymentTypes.CASH:
				paymentTypeObject = {
					sum: (multiplyer * (payment.get("partial_amount") || payment.get("price")))
				}
				break;
			case Payment.paymentTypes.CHECK:
				var checks = payment.get("checks_array");
				var checkObjects = [];
				for (var i = 0; i < checks.length; i++) {
					var checkObject = {
						sum: checks[i].amount,
						date: checks[i].date,
						number: checks[i].number,
						bank: payment.bank,
						branch: payment.branch,
						account: payment.account
					}
					checkObjects.push(checkObject)
				}
				paymentTypeObject = checkObjects
				break;
			case Payment.paymentTypes.BANK_TRANSFER:
				paymentTypeObject = {
					sum: (multiplyer * (payment.get("partial_amount") || payment.get("price"))),
					date: payment.get("updatedAt"),
					account: User.getPreferences().accounting_bank_account
				}
				break;
			case Payment.paymentTypes.OTHER:
				paymentTypeObject = {
					sum: (multiplyer * (payment.get("partial_amount") || payment.get("price")))
				}
				break;
			case Payment.paymentTypes.TRIAL:
				paymentTypeObject = {
					sum: (multiplyer * (payment.get("partial_amount") || payment.get("price")))
				}
				break;
			default:
				break;
		}

		return paymentTypeObject;
	}
}

export default new Accounting();
